import { configureStore } from "@reduxjs/toolkit";
import pikachuReducer from "../features/games/pikachu/redux/PPikachuSlice";
import userReducer from "./slices/userSlice";
import soundReducer from "./slices/soundSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    pikachu: pikachuReducer,
    sound: soundReducer,
  },
});

export default store;
