import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  countPlaySoundSelector,
  nameSoundSelector,
  playSound,
} from "../../store/slices/soundSlice";
import {
  PATH_OIT_SOUND_ENUM,
  PATH_PIKACHU_SOUND_ENUM,
} from "../../shared/constants/sound.contant";

export const Sound = () => {
  const dispatch = useDispatch();
  const pikachuClickAudioRef = useRef<any>();
  const pikachuCorrectAudioRef = useRef<any>();
  const pikachuWrongAudioRef = useRef<any>();
  const pikachuWinLevelAudioRef = useRef<any>();
  const pikachuWrongLevelAudioRef = useRef<any>();
  const nameSound = useSelector(nameSoundSelector);
  const countPlaySound = useSelector(countPlaySoundSelector);

  useEffect(() => {
    if (countPlaySound > 0) {
      let audioPlay = null;
      switch (nameSound as PATH_PIKACHU_SOUND_ENUM | PATH_OIT_SOUND_ENUM) {
        case PATH_PIKACHU_SOUND_ENUM.CLICK:
          audioPlay = pikachuClickAudioRef;
          break;
        case PATH_PIKACHU_SOUND_ENUM.CORRECT:
          audioPlay = pikachuCorrectAudioRef;
          break;
        case PATH_PIKACHU_SOUND_ENUM.WRONG:
          audioPlay = pikachuWrongAudioRef;
          break;
        case PATH_PIKACHU_SOUND_ENUM.WIN_LEVEL:
          audioPlay = pikachuWinLevelAudioRef;
          break;
        case PATH_PIKACHU_SOUND_ENUM.WRONG_LEVEL:
          audioPlay = pikachuWrongLevelAudioRef;
          break;
      }
      if (audioPlay) {
        audioPlay.current.play();
        dispatch(playSound({ nameSound: null }));
      }
    }
  }, [nameSound, countPlaySound]);

  return (
    <>
      {/* sound pikachu */}
      <audio
        src="/sound/pikachu/pikachu_click.mp3"
        style={{ display: "none" }}
        ref={pikachuClickAudioRef}
      />
      <audio
        src="/sound/pikachu/pikachu_correct.mp3"
        style={{ display: "none" }}
        ref={pikachuCorrectAudioRef}
      />
      <audio
        src="/sound/pikachu/pikachu_wrong.mp3"
        style={{ display: "none" }}
        ref={pikachuWrongAudioRef}
      />
      <audio
        src="/sound/pikachu/win_level.wav"
        style={{ display: "none" }}
        ref={pikachuWinLevelAudioRef}
      />
      <audio
        src="/sound/pikachu/wrong_level.wav"
        style={{ display: "none" }}
        ref={pikachuWrongLevelAudioRef}
      />
    </>
  );
};
