import HTTP_HUB_SERVICE from "../../../../configs/axios-hub.config";
import { pikachuApiConst } from "../constants/PPikachu.constant";

export const startApi = (data: any) => {
  return HTTP_HUB_SERVICE.post(pikachuApiConst.statGame(), data);
};

export const hintApi = (data: any) => {
  return HTTP_HUB_SERVICE.post(pikachuApiConst.hint, data);
};

export const checkTwoPointApi = (body: any) => {
  return HTTP_HUB_SERVICE.post(pikachuApiConst.check, body, {
    ignoreSpinner: true,
  });
};

export const pauseApi = (data: any) => {
  return HTTP_HUB_SERVICE.post(pikachuApiConst.pause, data);
};
