import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AuthRouter from "./AuthRouter";
import Layout from "../layout/Layout";
import Oauth2Callback from "../features/OAuth2Redirect";
import Dashboard from "../features/Dashboard/Dashboard";

import { APP_ROUTER_CONST } from "../shared/constants/app-router.constants";

const Profile = lazy(() => import("../features/Profile"));
const LayoutLine = lazy(() => import("../features/games/line/components/LayoutLine"));
const PrivacyPolicy = lazy(() => import("../features/article/PrivacyPolicy"));
const Game2048 = lazy(() => import("../features/games/2048/components/Game/Game2048"));
const LayoutPikachu = lazy(() => import("../features/games/pikachu/components/LayoutPikachu"));
const LayoutSudoku = lazy(() => import("../features/games/sudoku/components/LayoutSudoku"));
const TicTacToe = lazy(() => import("../features/games/tic-tac-toe/GameTicTacToe"));
const NewLogin = lazy(() => import("../features/Login/NewLogin"));

const AppRouter = () => {
	return (
		<>
			<Routes>
				<Route
					path={APP_ROUTER_CONST.layout.path}
					element={
						<Suspense fallback={<>...</>}>
							<Layout />
						</Suspense>
					}
				>
					<Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
					<Route
						path={APP_ROUTER_CONST.layout.children.dashboard.path}
						element={
							<Suspense fallback={<>...</>}>
								<AuthRouter>
									<Dashboard />
								</AuthRouter>
							</Suspense>
						}
					/>
					<Route path={APP_ROUTER_CONST.game2048.path} element={<Game2048 />} />
					<Route
						path={APP_ROUTER_CONST.pikachu.path}
						element={
							<Suspense fallback={<>...</>}>
								<AuthRouter>
									<LayoutPikachu />
								</AuthRouter>
							</Suspense>
						}
					/>
					<Route
						path={APP_ROUTER_CONST.line.path}
						element={
							<Suspense fallback={<>...</>}>
								<AuthRouter>
									<LayoutLine />
								</AuthRouter>
							</Suspense>
						}
					/>
					<Route
						path={APP_ROUTER_CONST.sudoku.path}
						element={
							<Suspense fallback={<>...</>}>
								<AuthRouter>
									<LayoutSudoku />
								</AuthRouter>
							</Suspense>
						}
					/>
					<Route
						path={APP_ROUTER_CONST.ticTacToe.path}
						element={
							<Suspense fallback={<>...</>}>
								<AuthRouter>
									<TicTacToe />
								</AuthRouter>
							</Suspense>
						}
					/>
					<Route
						path={APP_ROUTER_CONST.profile.path}
						element={
							<Suspense fallback={<>...</>}>
								<AuthRouter>
									<Profile />
								</AuthRouter>
							</Suspense>
						}
					/>
				</Route>
				<Route
					path={APP_ROUTER_CONST.login.path}
					element={
						<Suspense fallback={<>...</>}>
							<NewLogin />
						</Suspense>
					}
				/>
				<Route path="/new-login" element={<NewLogin />} />
				<Route path={APP_ROUTER_CONST.oauth2Callback.path} element={<Oauth2Callback />} />
				<Route
					path={APP_ROUTER_CONST.article.path}
					element={
						<Suspense fallback={<>...</>}>
							<PrivacyPolicy />
						</Suspense>
					}
				></Route>
			</Routes>
		</>
	);
};

export default AppRouter;
