import { Link } from "react-router-dom";
import Logo from "../../components/icons/Logo";
import ProfileItem from "../../components/ProfileItem";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: any) => state.user.currentUser);

  return (
    <Box component="header" sx={{ py: 2 }}>
      <Container>
        <Grid
          container
          justifyContent="space-between"
          alignContent="center"
          wrap="nowrap"
          sx={{ p: 0, m: 0 }}
        >
          <Grid item>
            <Link to="/" className="logo" style={{ alignSelf: "center" }}>
              <Logo />
            </Link>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignContent="center"
            wrap="nowrap"
          >
            <Grid item></Grid>
            <Grid item>
              {currentUser ? (
                <ProfileItem />
              ) : (
                <Link to="./login">{t("label", {ns: 'login'})}</Link>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NavBar;
