import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nameSound: "",
  countPlay: 0,
};

const soundSlice = createSlice({
  name: "soundSlice",
  initialState,
  reducers: {
    playSound(state, action) {
      return {
        ...state,
        nameSound: action.payload.nameSound,
        countPlay: state.countPlay + 1,
      };
    },
  },
  extraReducers: (build) => {},
});

export const { playSound } = soundSlice.actions;

export const nameSoundSelector = (state: any) => state.sound.nameSound;
export const countPlaySoundSelector = (state: any) => state.sound.countPlay;

export default soundSlice.reducer;
