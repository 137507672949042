import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackEnd from 'i18next-http-backend';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(BackEnd)
  .init({
    debug: true,
    fallbackLng: 'vi',
    saveMissing: true,
    resources: {
      en: {
        translation: {
          game: 'Game',
          score: "Score",
          like: "Like",
          dislike: "Dislike",
          save: "lưu",
          male: 'Male',
          female: 'Female',
          upload: "Upload"
        },
        login: {
          titlePage: "Login",
          label: 'Login',
          labelWith: "Login with"
        },
        logout: {
          title: 'Logout'
        },
        dashboard: {
          topGame: {
            title: 'Top game'
          }
        },
        user: {
          profile: "Your profile",
          updateButton: 'Update',
          titleUpdateProfilePropUp: "Update profile",
          achievementsProfile: "",
          fullName: "Full name",
          gender: "Gender",
          dateOfBirth: "Date of birth",
          mobilePhone: "Mobile phone",
          firstName: "First name",
          lastName: "Last name",
          nickName: "Nickname",
          aboutMe: "About me",
          titleYourProfilePicture: "Your profile picture",
          bodyYourProfilePicture: "Please upload a profile picture that is less than {{mb}} MB.",
        },
        game: {
          common: {
            detail: {
              title: 'Details'
            },
            comments: {
              title: 'Comments'
            }
          }
        }
      },
      vi: {
        translation: {
          game: 'Tự game',
          score: "Điểm",
          like: "Thích",
          dislike: "Không thích",
          save: "Lưu",
          male: 'Nam',
          female: 'Nữ',
          upload: "Tải lên"
        },
        login: {
          titlePage: "Đăng nhập",
          label: 'Đăng nhập',
          labelWith: "Đăng nhập với"
        },
        logout: {
          title: 'Đăng xuất'
        },
        dashboard: {
          topGame: {
            title: 'Game nổi bật'
          }
        },
        user: {
          profile: "Trang cá nhân",
          updateButton: 'Cập nhật',
          titleUpdateProfilePropUp: "Cập nhật thông tin",
          achievementsProfile: "Hồ sơ thành tích",
          fullName: "Họ và tên",
          gender: "Giới tính",
          dateOfBirth: "Ngày sinh",
          mobilePhone: "Số điện thoại",
          firstName: "Tên",
          lastName: "Họ",
          nickName: "Nickname",
          aboutMe: "Mô tả",
          titleYourProfilePicture: "Ảnh avatar",
          bodyYourProfilePicture: "Vui lòng tải ảnh avatar nhỏ hơn {{mb}} MB.",
        },
        game: {
          common: {
            detail: {
              title: 'Chi tiết'
            },
            comments: {
              title: 'Bình luận',

            },
            rank: {
              title: "Bãng xếp hạng"
            }
          }
        }
      }
    }
  })