export enum PATH_PIKACHU_SOUND_ENUM {
  CLICK = "/pikachu/pikachu_click.mp3",
  CORRECT = "/pikachu/pikachu_correct.mp3",
  WRONG = "/pikachu/pikachu_wrong.mp3",
  WIN_LEVEL = "/pikachu/win_level.wav",
  WRONG_LEVEL = "/pikachu/wrong_level.wav",
}
export enum PATH_OIT_SOUND_ENUM {
  CLICK = "/pikachu/pikachu_click.mp3",
  CORRECT = "/pikachu/pikachu_correct.mp3",
  WRONG = "/pikachu/pikachu_wrong.mp3",
  WIN_LEVEL = "/pikachu/win_level.wav",
  WRONG_LEVEL = "/pikachu/wrong_level.wav",
}
