import { ItemCellModel } from "../model/ItemCell.model";

export const buildData = (rawData: number[][]) => {
  return rawData.map((item1, index1) => {
    return item1.map(
      (item2, index2) =>
        ({
          value: item2,
          x: index1,
          y: index2,
        }) as ItemCellModel,
    );
  });
};

export const buildDataEmpty = (data: number[][]) => {
  return data.map((item1: any, index: number) => {
    return item1.map((item2: any, index1: number) => {
      item2.value = 0;
      item2.class = "";
      return item2;
    });
  });
};

export const buildDataHint = (point1: any, point2: any, data: number[][]) => {
  return data.map((item1: any, index: number) => {
    return item1.map((item2: any, index1: number) => {
      if (
        (item2.x === point1.x && item2.y === point1.y) ||
        (item2.x === point2.x && item2.y === point2.y)
      ) {
        item2.class = "hint";
      }
      return item2;
    });
  });
};

export const isCellEmpty = (item: any) => {
  return item.value === 0;
};

export const activeItemData = (item: any, data: any[], active: any) => {
  return data.map((item1: any, index: number) => {
    return item1.map((item2: any, index1: number) => {
      if (index == item.x && index1 == item.y) {
        if (active) item2.class = "active";
        else item2.class = null;
      }
      return item2;
    });
  });
};

export const isSameItemSelected = (selectedItem: any, item: any) => {
  return selectedItem.x == item.x && selectedItem.y == item.y;
};

export const isWinLevel = (data: any[][]) => {
  if (data) {
    for (let i = 0; i < data.length - 1; i++) {
      for (let y = 0; y < data[i].length - 1; y++) {
        if (data[i][y].value != 0) {
          return false;
        }
      }
    }
  }

  return true;
};

// export const playClickSound = (isActiveSound: boolean) => {
//   const audio = new Audio("/sound/pikachu/pikachu_click.mp3");
//   if (isActiveSound) {
//     audio.play();
//   } else {
//     audio.pause();
//   }
// }

// export const playWrongSound = (isActiveSound: boolean) => {
//   const audio = new Audio("/sound/pikachu/pikachu_wrong.mp3");
//   if (isActiveSound) {
//     audio.play();
//   } else {
//     audio.pause();
//   }
// }

// export const playCorrectSound = (isActiveSound: boolean) => {
//   const audio = new Audio("/sound/pikachu/pikachu_correct.mp3");
//   if (isActiveSound) {
//     audio.play();
//   } else {
//     audio.pause();
//   }
// }

// export const winLevelSound = (isActiveSound: boolean) => {
//   const audio = new Audio("/sound/pikachu/win_level.wav");
//   if (isActiveSound) {
//     audio.play();
//   } else {
//     audio.pause();
//   }
// }

// export const wrongLevelSound = (isActiveSound: boolean) => {
//   const audio = new Audio("/sound/pikachu/wrong_level.wav");
//   if (isActiveSound) {
//     audio.play();
//   } else {
//     audio.pause();
//   }
// }
