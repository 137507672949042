import AppRouter from "./components/Approuter";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "@lottiefiles/lottie-player";
import { Sound } from "./features/sound/Sound";

function App() {

  window.addEventListener("orientationchange", function () {
    window.location.reload();
  });

  return (
    <>
      <AppRouter />
      <Sound />
      <ToastContainer />
    </>
  );
}

export default App;
