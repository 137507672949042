import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { startApi } from "../api/PPikachu.api";
import { buildData } from "../util/PPikachu.util";
import { TURN_ID } from "../../../../shared/constants/key-store.constant";

const initialState = {
  point1: null,
  dataBoard: null,
  hint: 0,
  level: 1,
  score: 0,
  shuffle: 0,
  time: null,
  turnId: localStorage.getItem(TURN_ID),
  winScore: null,
  isActiveSound: true,
};

export const startApiAsync = createAsyncThunk(
  "pikachu/startApi",
  async (params: any) => {
    try {
      const res = await startApi(params);
      return res.data;
    } catch (error: any) {
      throw Error(error);
    }
  },
);

export const checkTwoPointApiAsync = createAsyncThunk(
  "pikachu/checkTwoPointApi",
  async (params: any) => {
    try {
    } catch (error: any) {
      throw Error(error);
    }
  },
);

const pikachuSlice = createSlice({
  name: "pikachu",
  initialState,
  reducers: {
    updateDataBoard(state, action) {
      return {
        ...state,
        dataBoard: action.payload,
      };
    },
    setPoint1(state, action) {
      return {
        ...state,
        point1: action.payload,
      };
    },
    toggleSound(state, action) {
      return {
        ...state,
        isActiveSound: action.payload,
      };
    },
    setDataBoard(state, action) {
      return {
        ...state,
        dataBoard: action.payload,
      };
    },
  },
  extraReducers: (build) => {
    build.addCase(startApiAsync.fulfilled, (state: any, actions: any) => {
      const { hint, level, score, shuffle, time, turnId, winScore, board } =
        actions.payload;
      if (!state.turnId) {
        localStorage.setItem(TURN_ID, turnId);
      }
      return {
        ...state,
        dataBoard: buildData(board),
        hint,
        level,
        score,
        shuffle,
        time,
        turnId,
        winScore,
      };
    });
  },
});

export const { updateDataBoard, setPoint1, toggleSound, setDataBoard } =
  pikachuSlice.actions;

export default pikachuSlice.reducer;
