import MostPopular from "../MostPopular/MostPopular";

const Dashboard = () => {
  return (
    <>
      {/* <Banner /> */}
      <MostPopular />
      {/* <GameLibrary /> */}
    </>
  );
};

export default Dashboard;
