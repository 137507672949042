import axios from "axios";
import { TIMEOUT } from "../shared/constants/axios.constant";
import { paramsSerializer } from "../shared/utils/axios.util";

const HTTP_USER_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_USER_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: TIMEOUT,
  // paramsSerializer,
});

export default HTTP_USER_SERVICE;
