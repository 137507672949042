import { useEffect, useState } from "react";
import { getAllGames } from "../../shared/api/game.api";
import { useNavigate } from "react-router-dom";
import { buildFileUrl } from "../../shared/utils/file.util";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { useTranslation } from "react-i18next";

export interface MostPoPularModel {
	nameGame: string;
	category: string;
	url: string;
}

const MostPopular = () => {

	const navigate = useNavigate();
	const [games, setGames] = useState<any>([]);
	const { t } = useTranslation();

	useEffect(() => {
		getAllGames().then((res) => {
			setGames(res.data);
		});
	}, []);
	return (
		<>
			<Box>
				<Typography variant="h1" color={"black"} fontSize={"2.4rem"} mb={"calc(1rem + 12px)"} mt={"1rem"}>
					{t('topGame.title', {ns: 'dashboard'})}
				</Typography>

				{/* show all game */}
				<Grid container spacing={"1rem"}>
					{games &&
						games.length > 0 &&
						games.map((i: any, index: number) => {
							return (
								<Grid item xs={12} sm={6} md={4} lg={3} key={i.id}>
									<Card sx={{ maxWidth: "100%", borderRadius: "9px", boxShadow: '2px 4px 12px rgba(0,0,0,.08)' }}>
										<CardActionArea
											onClick={() => {
												navigate(`/${i.code}/${i.id}`);
											}}
										>
											<CardMedia component="img" height="auto" image={i.avatarUrl && buildFileUrl(i.avatarUrl)} alt={i.name} sx={{ p: "1rem", pb: 0 }} />
											<CardContent>
												<Typography gutterBottom variant="h5" component="div">
													{i.name}
												</Typography>
												<Tooltip title="Số lượt chơi" placement="top-start">
													<Stack direction={"row"} color="text.secondary" mb={"0.5rem"} spacing={"0.5rem"} alignItems={"center"}>
														<PersonOutlineOutlinedIcon />
														<Typography component={"div"}>{i.nbrOfPlay}</Typography>
													</Stack>
												</Tooltip>

												<Tooltip title="Số lượt thích" placement="top-start">
													<Stack direction={"row"} color="text.secondary" spacing={"0.5rem"} alignItems={"center"}>
														<ThumbUpOutlinedIcon />
														<Typography component={"div"}>{i.nbrOfLike}</Typography>
													</Stack>
												</Tooltip>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							);
						})}
				</Grid>
			</Box>
		</>
	);
};

export default MostPopular;
