import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { currentUserApi } from "../../shared/api/user.api";

const initialState = {
  currentUser: null,
};

export const currentUserApiAsync = createAsyncThunk(
  "currentUser/currentUserApi",
  async () => {
    try {
      const res = await currentUserApi();
      return res.data;
    } catch (error: any) {
      throw Error(error);
    }
  },
);

const userSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    updateCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
  },
  extraReducers: (build) => {
    build.addCase(currentUserApiAsync.fulfilled, (state: any, actions: any) => {
      return {
        ...state,
        currentUser: actions.payload,
      };
    });
  },
});

export const { updateCurrentUser } = userSlice.actions;

export const currentUserSelector = (state: any) => state.user.currentUser;

export default userSlice.reducer;
