import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./font.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import setupAxiosInterceptors from "./configs/axios-interceptor";
import HTTP_WEB_SERVICE from "./configs/axios-web.config";
import HTTP_HUB_SERVICE from "./configs/axios-hub.config";
import store from "./store/store";
import { Provider } from "react-redux";
import HTTP_USER_SERVICE from "./configs/axios-user.config";
import "./i18n/i18n"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

setupAxiosInterceptors(
  () => () => {},
  () => () => {},
  () => () => {},
  HTTP_WEB_SERVICE,
);

setupAxiosInterceptors(
  () => () => {},
  () => () => {},
  () => () => {},
  HTTP_HUB_SERVICE,
);

setupAxiosInterceptors(
  () => () => {},
  () => () => {},
  () => () => {},
  HTTP_USER_SERVICE,
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={"loading..."}>
        <App />
      </Suspense>
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
