import React from "react";
import { AvatarDefault } from "../AvatarDefault";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTER_CONST } from "../../shared/constants/app-router.constants";
import { ACCESS_TOKEN } from "../../shared/constants/key-store.constant";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentUser } from "../../store/slices/userSlice";
import { Avatar, Hidden, Typography } from "@mui/material";
import { buildFileUrl } from "../../shared/utils/file.util";
import { useTranslation } from "react-i18next";

const ProfileItem = () => {
	const currentUser = useSelector((state: any) => state.user.currentUser);
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleProfileAction = () => {
		handleClose();
		navigate(APP_ROUTER_CONST.profile.path);
	};

	const handleSignOutAction = async () => {
		handleClose();
		await dispatch(updateCurrentUser);
		// navigate(location.pathname, { replace: true, relative: "path" });
		localStorage.removeItem(ACCESS_TOKEN);
		window.location.reload();
	};

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				className="d-flex align-items-center"
				style={{
					padding: "8px 16px",
					gap: "16px",
					borderRadius: "100px",
					background: "#F5F6F7",
					cursor: "pointer",
					userSelect: "none",
				}}
			>
				<Hidden smDown>
					<Typography
						sx={{
							color: "#787F84",
							fontWeight: "500",
							fontSize: "16px",
							textTransform: "initial",
						}}
					>
						{currentUser?.account}
					</Typography>
				</Hidden>
				{currentUser?.avatarUrl ? (
					<Avatar alt={currentUser.account} src={buildFileUrl(currentUser?.avatarUrl)} id={currentUser.avatarUrl} sx={{ width: "40px", height: "40px" }} />
				) : (
					<AvatarDefault />
				)}
			</Button>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				sx={{
					marginTop: "10px",
				}}
			>
				<MenuItem
					onClick={handleProfileAction}
					sx={{
						minWidth: "145px",
					}}
				>
					{t('profile', {ns: 'user'})}
				</MenuItem>
				<MenuItem onClick={handleSignOutAction}>{t('title', {ns: 'logout'})}</MenuItem>
			</Menu>
		</div>
	);
};

export default ProfileItem;
