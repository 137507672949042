export const APP_ROUTER_CONST = {
  layout: {
    path: "/",
    roles: [],
    children: {
      overview: {
        path: "/overview",
        roles: [],
      },
      listGame: {
        path: "list-game",
        roles: [],
      },
      dashboard: {
        path: "/dashboard",
        role: [],
      },
    },
  },
  login: {
    path: "/login",
  },
  profile: {
    path: "/profile",
  },
  oauth2Callback: {
    path: "/oauth2/redirect",
  },
  game2048: {
    path: "2048/:id",
  },
  pikachu: {
    path: "pikachu/:id",
  },
  line: {
    path: "line98/:id",
  },
  sudoku: {
    path: "sudoku/:id",
  },
  ticTacToe: {
    path: "tic-tac-toe/:id",
  },
  article: {
    path: "article/privacy-policy",
  },
};
