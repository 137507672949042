import HTTP_USER_SERVICE from "../../configs/axios-user.config";

export const currentUserApi = () => {
  return HTTP_USER_SERVICE.get("/api/user/me");
};

export const updateUserApi = (data: any) => {
  return HTTP_USER_SERVICE.put("/api/user/me", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
