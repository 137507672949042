import { LikeEnum } from "./LikeEnum.enum";
import { IMAGES_THEME1 } from "./theme/pikachu-them1.constant";
import { IMAGES_THEME2 } from "./theme/pikachu-them2.constant";

export const pikachuApiConst = {
  statGame: () => `/api/pikachu/start`,
  hint: `/api/pikachu/hint`,
  check: `/api/pikachu/check`,
  pause: `/api/pikachu/pause`,
};

const VERTICAL_AMOUNT = 9;
const HORIZON_AMOUNT = 16;
const TILE_SIZE = 80;
export const MAX_HINT = 10;
export const MAX_SHUFFLE = 10;

export const THEME_PIKACHU = [IMAGES_THEME1, IMAGES_THEME2];

export default {};
