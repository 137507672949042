import * as React from "react";
export interface LogoProps {
  width?: number;
  height?: number;
}
const Logo = ({ width, height, ...props }: LogoProps) => (
  <svg
    width={width || 167}
    height={height || 40}
    viewBox="0 0 167 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 2.5A2.5 2.5 0 0 0 37.5 0h-35A2.5 2.5 0 0 0 0 2.5v35A2.5 2.5 0 0 0 2.5 40h18.954a2.5 2.5 0 0 0 1.767-.732L39.268 23.22A2.5 2.5 0 0 0 40 21.454V2.5Z"
      fill="#2377FC"
    />
    <path
      d="M5 18.75c0-.69.56-1.25 1.25-1.25h10c.69 0 1.25.56 1.25 1.25v10.732c0 .332-.132.65-.366.884l-4.268 4.268a1.25 1.25 0 0 1-.884.366H6.25C5.56 35 5 34.44 5 33.75v-15Zm28.75-1.25c.69 0 1.25-.56 1.25-1.25v-10C35 5.56 34.44 5 33.75 5H23.018c-.332 0-.65.132-.884.366l-4.268 4.268a1.25 1.25 0 0 0-.366.884v5.732c0 .69.56 1.25 1.25 1.25h15Z"
      fill="#F5F6F7"
    />
    <text
      x={50}
      y={30}
      fill="#000"
      style={{
        fontSize: 28,
        fontWeight: 500,
        fontFamily: "Poppins",
      }}
    >
      {" GameDi "}
    </text>
  </svg>
);
export default Logo;
