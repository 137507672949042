export const isImageFile = (file: File) => {
  if (
    file.type.startsWith("image/") &&
    (file.name.toLowerCase().endsWith(".png") ||
      file.name.toLowerCase().endsWith(".jpg"))
  ) {
    return true;
  }

  return false;
};

export const isZeroSizeFile = (file: File) => {
  if (file.size === 0) {
    return true;
  }

  return false;
};

export const isMaxSizeFile = (file: File, maxSizeMB: number) => {
  const bt = 1024 * 1024 * maxSizeMB;
  if (file.size > bt) {
    return true;
  }

  return false;
};

export const isErrorFileName = (file: File) => {
  return !/^[\w.;_ ]*$/.test(file.name);
};

export const buildFileUrl = (pathName: string) => {
  if (pathName) {
    if (pathName.startsWith("http")) {
      return pathName;
    }

    return `${process.env.REACT_APP_FILE_URL}/${pathName}`;
  }

  return pathName;
};
