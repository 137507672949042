import React from "react";
import PropTypes from "prop-types";
import { Navigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ACCESS_TOKEN } from "../../shared/constants/key-store.constant";

const OAuth2RedirectHandler = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const error = searchParams.get("error");

  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);
    window.location.href = "/";
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
        replace
      />
    );
  } else {
    toast.error(error || "Có lỗi xảy ra với hệ thống");
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
        replace
      />
    );
  }
};

OAuth2RedirectHandler.propTypes = {};

export default OAuth2RedirectHandler;
