import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACCESS_TOKEN } from "../../shared/constants/key-store.constant";
import {
  currentUserApiAsync,
  updateCurrentUser,
} from "../../store/slices/userSlice";

const AuthRouter = (props: any) => {
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initial();
  }, [accessToken, currentUser]);

  const initial = async () => {
    if (accessToken && !currentUser) {
      try {
        await dispatch(currentUserApiAsync());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      dispatch(updateCurrentUser);
    }
  };

  if (isLoading) {
    return null;
  }

  return props.children;
};

export default AuthRouter;
