import HTTP_WEB_SERVICE from "../../configs/axios-web.config";
import { LikeEnum } from "../../features/games/pikachu/constants/LikeEnum.enum";

export const gameApiConst = {
  like: (id: any, type: LikeEnum) => `/api/games/${id}/like/${type}`,
};

export const getAllGames = () => {
  return HTTP_WEB_SERVICE.get("/api/games");
};

export const getDetailGame = (id: number) => {
  return HTTP_WEB_SERVICE.get(`/api/games/${id}`);
};

export const commentGame = (data: any) => {
  return HTTP_WEB_SERVICE.post("/api/games/comments", data);
};

export const likeOrDislikeApi = (id: any, type: LikeEnum) => {
  return HTTP_WEB_SERVICE.post(gameApiConst.like(id, type));
};
