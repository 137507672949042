import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";

const Layout = () => {
	return (
		<>
			<NavBar />
			<Box sx={{ background: "#f1f1f1", py: 1.5 }}>
				<Container component={"div"}>
          <Outlet />
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default Layout;
